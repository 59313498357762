@font-face {
  font-family: 'FSMeWeb';
  src: url('./fonts/FSMeWeb-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FSMeWeb';
  src: url('./fonts/FSMeWeb-Italic.otf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'FSMeWeb';
  src: url('./fonts/FSMeWeb-Bold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'FSMeWeb';
  src: url('./fonts/FSMeWeb-BoldItalic.otf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

html,
body {
  font-family: 'FSMeWeb';
  height: 100%;
}
